"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsByFunction = exports.PERMISSION_CATEGORY_MAP = exports.PermissionCategory = exports.PERMISSION_MAP = exports.Permissions = void 0;
var Permissions;
(function (Permissions) {
    Permissions["AccessAdmin"] = "access_admin";
    Permissions["AccessPos"] = "access_pos";
    Permissions["ManageAdminDefaultInfo"] = "manage_admin_default_info";
    Permissions["ManageAdditionalPermissions"] = "manage_additional_permissions";
    Permissions["ManageTicketPackage"] = "manage_ticket_package";
    Permissions["ManageProduct"] = "manage_product";
    Permissions["ManageExternalPresaleTicket"] = "manage_external_presale_ticket";
    Permissions["ManagePresaleTicket"] = "manage_presale_ticket";
    Permissions["ManagePartnershipTicket"] = "manage_partnership_ticket";
    Permissions["ManageCorpTicket"] = "manage_corp_ticket";
    Permissions["ManageBpTicket"] = "manage_bp_ticket";
    Permissions["ManageCoupon"] = "manage_coupon";
    Permissions["ManageMarketingCoupon"] = "manage_marketing_coupon";
    Permissions["ManagePosCoupon"] = "manage_pos_coupon";
    Permissions["CreateManualSales"] = "create_manual_sales";
    Permissions["ManagePark"] = "manage_park";
    Permissions["AccessToFullPersonalInfo"] = "access_to_full_personal_info";
    Permissions["IssuePersonalCoupon"] = "issue_personal_coupon";
    Permissions["CreateGroupReservation"] = "create_group_reservation";
    Permissions["UpdateGroupReservation"] = "update_group_reservation";
    Permissions["ManageClient"] = "manage_client";
    Permissions["SetSeasonSchedule"] = "set_season_schedule";
    Permissions["ManageShootingSchedule"] = "manage_shooting_schedule";
    Permissions["ManageHolidaySchedule"] = "manage_holiday_schedule";
    Permissions["ManageNotice"] = "manage_notice";
    Permissions["ManageEvent"] = "manage_event";
    Permissions["ManageOnlineReservationCapacity"] = "manage_online_reservation_capacity";
    Permissions["DownloadSalesExcel"] = "download_sales_excel";
    Permissions["DownloadPaymentExcel"] = "download_payment_excel";
    Permissions["DownloadCouponExcel"] = "download_coupon_excel";
    Permissions["DownloadPresaleExcel"] = "download_presale_excel";
    Permissions["DownloadGroupReservationExcel"] = "download_group_reservation_excel";
    Permissions["ManageRecalculateSales"] = "manage_recalculate_sales";
    Permissions["PosOperating"] = "pos_operating";
    Permissions["PosManuallyUseUserTicket"] = "pos_manually_use_user_ticket";
    Permissions["PosUpdateEnterAt"] = "pos_update_enter_at";
    Permissions["PosUpdateExitAt"] = "pos_update_exit_at";
    Permissions["PosAccessSettingMenu"] = "pos_access_setting_menu";
    Permissions["PosManageMasterBand"] = "pos_manage_master_band";
})(Permissions = exports.Permissions || (exports.Permissions = {}));
exports.PERMISSION_MAP = {
    [Permissions.AccessAdmin]: 'Admin 접근',
    [Permissions.AccessPos]: 'POS 접근',
    [Permissions.ManageAdminDefaultInfo]: '기본 정보 설정',
    [Permissions.ManageAdditionalPermissions]: '추가 권한 설정',
    [Permissions.ManageTicketPackage]: '입장권/패키지 상품 생성, 수정',
    [Permissions.ManageProduct]: '기타/F&B 상품 생성, 수정',
    [Permissions.ManageCorpTicket]: '법인회원권 상품 생성, 수정',
    [Permissions.ManagePresaleTicket]: '선발행권 상품 생성, 발급, 수정',
    [Permissions.ManagePartnershipTicket]: '제휴권 상품 생성, 수정 ',
    [Permissions.ManageExternalPresaleTicket]: '외부연동 선발행권 상품 생성, 수정',
    [Permissions.ManageBpTicket]: '비즈니스파트너몰 상품 생성, 수정',
    [Permissions.ManageCoupon]: '유저 쿠폰 생성, 수정, 발급',
    [Permissions.ManageMarketingCoupon]: '외부발행 쿠폰 생성, 수정, 발급',
    [Permissions.ManagePosCoupon]: 'POS 할인 생성, 수정',
    [Permissions.CreateManualSales]: '매출 수기 등록',
    [Permissions.ManagePark]: '지점 관리',
    [Permissions.AccessToFullPersonalInfo]: '개인정보 전체 조회',
    [Permissions.IssuePersonalCoupon]: '쿠폰 개별 발급',
    [Permissions.CreateGroupReservation]: '단체 예약 등록',
    [Permissions.UpdateGroupReservation]: '단체 예약 수정, 취소, SMS 발송',
    [Permissions.ManageClient]: '단체/거래처 생성, 수정',
    [Permissions.SetSeasonSchedule]: '성수기/비수기 시즌 설정',
    [Permissions.ManageShootingSchedule]: '촬영 일정 등록, 삭제',
    [Permissions.ManageHolidaySchedule]: '휴장일 등록, 삭제',
    [Permissions.ManageNotice]: '공지사항 등록, 삭제',
    [Permissions.ManageEvent]: '이벤트 등록, 삭제',
    [Permissions.ManageOnlineReservationCapacity]: '온라인 예약 인원 설정',
    [Permissions.DownloadSalesExcel]: '매출내역 엑셀 다운로드',
    [Permissions.DownloadPaymentExcel]: '결제내역 엑셀 다운로드',
    [Permissions.DownloadCouponExcel]: '쿠폰 번호 엑셀 다운로드',
    [Permissions.DownloadPresaleExcel]: '선발행권 번호 엑셀 다운로드',
    [Permissions.DownloadGroupReservationExcel]: '단체 예약 현황 엑셀 다운로드',
    [Permissions.ManageRecalculateSales]: '데이터 재집계',
    [Permissions.PosOperating]: '기본 기능',
    [Permissions.PosManuallyUseUserTicket]: '미사용 티켓 사용처리',
    [Permissions.PosUpdateEnterAt]: '입장시간 조정',
    [Permissions.PosUpdateExitAt]: '퇴장시간 무제한 조정',
    [Permissions.PosAccessSettingMenu]: '설정 접근',
    [Permissions.PosManageMasterBand]: '마스터 밴드 관리 접근',
};
var PermissionCategory;
(function (PermissionCategory) {
    PermissionCategory["Access"] = "Access";
    PermissionCategory["AuthSettings"] = "AuthSettings";
    PermissionCategory["ManageItems"] = "ManageItems";
    PermissionCategory["ManageDiscount"] = "ManageDiscount";
    PermissionCategory["ManageSales"] = "ManageSales";
    PermissionCategory["ManagePark"] = "ManagePark";
    PermissionCategory["ManageCustomer"] = "ManageCustomer";
    PermissionCategory["ManageGroup"] = "ManageGroup";
    PermissionCategory["ManageSchedule"] = "ManageSchedule";
    PermissionCategory["ManageWebsite"] = "ManageWebsite";
    PermissionCategory["ExcelDownload"] = "ExcelDownload";
    PermissionCategory["POS"] = "POS";
})(PermissionCategory = exports.PermissionCategory || (exports.PermissionCategory = {}));
exports.PERMISSION_CATEGORY_MAP = {
    [PermissionCategory.Access]: '서비스 접근',
    [PermissionCategory.AuthSettings]: '어드민 관리',
    [PermissionCategory.ManageItems]: '상품 관리',
    [PermissionCategory.ManageDiscount]: '할인 관리',
    [PermissionCategory.ManageSales]: '매출 관리',
    [PermissionCategory.ManagePark]: '지점 관리',
    [PermissionCategory.ManageCustomer]: '고객 관리',
    [PermissionCategory.ManageGroup]: '단체 관리',
    [PermissionCategory.ManageSchedule]: '일정 관리',
    [PermissionCategory.ManageWebsite]: '웹사이트 관리',
    [PermissionCategory.ExcelDownload]: '엑셀 다운로드',
    [PermissionCategory.POS]: 'POS',
};
exports.PermissionsByFunction = {
    [PermissionCategory.Access]: [Permissions.AccessAdmin, Permissions.AccessPos],
    [PermissionCategory.AuthSettings]: [
        Permissions.ManageAdminDefaultInfo,
        Permissions.ManageAdditionalPermissions,
    ],
    [PermissionCategory.ManageItems]: [
        Permissions.ManageTicketPackage,
        Permissions.ManageProduct,
        Permissions.ManageExternalPresaleTicket,
        Permissions.ManagePresaleTicket,
        Permissions.ManagePartnershipTicket,
        Permissions.ManageCorpTicket,
        Permissions.ManageBpTicket,
    ],
    [PermissionCategory.ManageDiscount]: [
        Permissions.ManageCoupon,
        Permissions.ManageMarketingCoupon,
        Permissions.ManagePosCoupon,
    ],
    [PermissionCategory.ManageSales]: [
        Permissions.CreateManualSales,
        Permissions.ManageRecalculateSales,
    ],
    [PermissionCategory.ManageCustomer]: [
        Permissions.AccessToFullPersonalInfo,
        Permissions.IssuePersonalCoupon,
    ],
    [PermissionCategory.ManageGroup]: [
        Permissions.CreateGroupReservation,
        Permissions.UpdateGroupReservation,
        Permissions.ManageClient,
    ],
    [PermissionCategory.ManagePark]: [Permissions.ManagePark],
    [PermissionCategory.ManageSchedule]: [
        Permissions.SetSeasonSchedule,
        Permissions.ManageShootingSchedule,
        Permissions.ManageHolidaySchedule,
    ],
    [PermissionCategory.ManageWebsite]: [
        Permissions.ManageNotice,
        Permissions.ManageEvent,
        Permissions.ManageOnlineReservationCapacity,
    ],
    [PermissionCategory.ExcelDownload]: [
        Permissions.DownloadSalesExcel,
        Permissions.DownloadPaymentExcel,
        Permissions.DownloadCouponExcel,
        Permissions.DownloadPresaleExcel,
        Permissions.DownloadGroupReservationExcel,
    ],
    [PermissionCategory.POS]: [
        Permissions.PosOperating,
        Permissions.PosManuallyUseUserTicket,
        Permissions.PosUpdateEnterAt,
        Permissions.PosUpdateExitAt,
        Permissions.PosAccessSettingMenu,
        Permissions.PosManageMasterBand,
    ],
};
